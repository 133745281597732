import React from "react"
import Img from "gatsby-image/index"
import Layout from "../components/layout"
import { useSiteMetadata } from ".././hooks"

import {
  getIcon,
  notifyPhoneClick,
  notifyMessengerClick,
  sendEventToGA4,
} from "../utils/index"
import { Icon } from "../components/icon"

import styles from "./contact.module.scss"

const ContactPage = ({ location, data }) => {
  const {
    contacts: { phone, email, instagram, facebook, youtube, telegram, viber },
  } = useSiteMetadata()

  const title = "Контакти"
  const description = `Телефон: ${phone}. Пошта: ${email}. Facebook: ${facebook}`

  const handlePhoneClick = () => {
    sendEventToGA4({
      action: "phone_click",
      category: "phone",
      label: "Contact Page Phone",
    })

    notifyPhoneClick({
      category: "Сторінка",
      label: "Контакти - телефон в списку",
    })
  }

  const handleMessengerClick = messenger => {
    sendEventToGA4({
      action: "social_click",
      category: "social",
      label: `Contact Page ${messenger}`,
    })

    notifyMessengerClick({
      messenger,
      category: "Сторінка",
      label: "Контакти - посилання в списку",
    })
  }

  return (
    <Layout
      contentClassName="layout-content"
      title={title}
      description={description}
      location={location}
    >
      <h1>{title}</h1>
      <div className={styles.saleDepartmentWrapper}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          className={styles.saleDepartmentImg}
          title="Відділ продажу"
        />
        <div className={styles.saleDepartmentDetails}>
          <h2>Запис на перегляд</h2>
          <p>Пн-Cб: 9:00-19:00 (без перерви)</p>
          <p>Нд: вихідний</p>
          <p className={styles.red}>
            *обов’язково повідомте нас про Ваш візит.
          </p>
          <p>
            Адреса:{" "}
            <a
              href="https://goo.gl/maps/xtX3Ty3b9zNgexJw7"
              rel="noreferrer"
              target="_blank"
            >
              КМ Нова Ходосівка
            </a>
          </p>
          <p>с. Ходосівка, вул. Степова</p>
        </div>
      </div>
      <div className={styles.contactWrapper}>
        <a
          className={[styles.contactBtn, styles.contactBtnTelegram].join(" ")}
          href={`tg://resolve?domain=${telegram}`}
          onClick={() => handleMessengerClick("Telegram")}
        >
          <Icon name={"telegram"} icon={getIcon("telegram")} />
          <span>
            Написати нам в <b>Telegram</b>
          </span>
        </a>
        <a
          className={[styles.contactBtn, styles.contactBtnViber].join(" ")}
          href={`viber://chat?number=${viber}`}
          onClick={() => handleMessengerClick("Viber")}
        >
          <Icon name={"viber"} icon={getIcon("viber")} />
          <span>
            Написати нам в <b>Viber</b>
          </span>
        </a>
        <a
          className={[styles.contactBtn, styles.contactBtnPhone].join(" ")}
          href={`tel:${phone}`}
          onClick={handlePhoneClick}
        >
          <Icon name={"phone"} icon={getIcon("phone")} />
          <span>
            Зателефонувати <b>{phone}</b>
          </span>
        </a>
      </div>
      <div className={styles.talksThemeWrapper}>
        <div>
          <Icon name={"бронювання"} icon={getIcon("account check")} />
          <span>бронювання часу менеджера спеціально для вас;</span>
        </div>
        <div>
          <Icon name={"розрахунок"} icon={getIcon("filter plus outline")} />
          <span>розрахунок вартості;</span>
        </div>
        <div>
          <Icon name={"консультація"} icon={getIcon("cart plus")} />
          <span>консультація по умовах придбання та будівництво;</span>
        </div>
        <div>
          <Icon name={"екскурсія"} icon={getIcon("two man")} />
          <span>екскурсія по нашим об'єктам;</span>
        </div>
        <div>
          <Icon name={"дизайн"} icon={getIcon("compass")} />
          <span>дизайн та перепланування</span>
        </div>
      </div>
      <div className={styles.socialWrapper}>
        <a
          href={instagram}
          className={styles.instagram}
          target="_blank"
          rel="noreferrer"
        >
          <Icon name={"instagram"} icon={getIcon("instagram")} />
          <span>Instagram</span>
          <span className={styles.socialDescription}>
            Пости та історії нових та актуальних будинків
          </span>
        </a>
        <a
          href={facebook}
          className={styles.facebook}
          target="_blank"
          rel="noreferrer"
        >
          <Icon name={"facebook"} icon={getIcon("facebook")} />
          <span>Facebook</span>
          <span className={styles.socialDescription}>
            Пости нових та актуальних будинків. Магазин будинків
          </span>
        </a>
        <a
          href={youtube}
          className={styles.youtube}
          target="_blank"
          rel="noreferrer"
        >
          <Icon name={"YouTube"} icon={getIcon("youtube")} />
          <span>YouTube</span>
          <span className={styles.socialDescription}>
            Відеоогляди будинків з коментарями
          </span>
        </a>
      </div>
      <div className={styles.emailWrapper}>
        <Icon name={"email"} icon={getIcon("email")} />
        <a href={`mailto:${email}`} className={styles.emailBtn}>
          написати електроний лист
        </a>
        <a href={`mailto:${email}`} className={styles.emailText}>
          {email}
        </a>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "sale-department.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          sizes
          src
          srcSet
        }
      }
    }
  }
`

export default ContactPage
